import axios from 'axios'
import crello from '@/plugins/crello'
import { useAxios } from '@/composables/useAxios';

const ENDPOINT = "/visualIdentities-requests"
const vistaCreateCdnUrl = crello.getCdnUrl()

const DEFAULT_PAGE_SIZE = 80;

const getMediaType = (template: {previewScreenVideoUrl?: string, previewImageUrls: string[]}) => {
  if ('previewScreenVideoUrl' in template) {
    return 'video'
  } else if (template.previewImageUrls.length > 1) {
    return 'carousel'
  } else {
    return 'image'
  }
}

const fetchTemplates = async ({pageSize = DEFAULT_PAGE_SIZE, startAt = 0}) => {
  try {
    const params = {
      pageSize,
      startAt
    }

    const { httpFunctions } = await useAxios()

    const { data, count } = (await httpFunctions.get<{data: any[], count: number}>(ENDPOINT, { params })).data
    const visualIdentities = data
        .map((visualIdentity: any) => {
          try {
            const templates = visualIdentity.templates
              .map((template: any) => {
                const previewImageUrls = [];
                let previewScreenVideoUrl = null;
            
                if ("previewImageUrls" in template && template.previewImageUrls) {
                  const previews = template.previewImageUrls?.map((preview: string) => `${vistaCreateCdnUrl}${preview}`);
                  previewImageUrls.push(...previews);
                }
            
                if ("previewScreenVideoUrl" in template) {
                  previewScreenVideoUrl = `${vistaCreateCdnUrl}${template.previewScreenVideoUrl}`;
                }
            
                const thumbnail = previewImageUrls[0] || null;
            
                
                return {
                  ...template,
                  thumbnail,
                  mediaType: getMediaType({ ...(previewScreenVideoUrl && {previewScreenVideoUrl}), previewImageUrls }),
                  formatKey: template.formatKey,
                  ...(previewImageUrls.length && { previewImageUrls }),
                  ...(previewScreenVideoUrl && { previewScreenVideoUrl })
                }
              });
  
            const feedTemplate = templates
              .find((template: any) => template.formatKey === "instagramSM" && template.mediaType === "image");

              const thumbnail = feedTemplate?.thumbnail || templates[0]?.thumbnail || null;
  
            return {
              createdAt: visualIdentity.createdAt,
              id: visualIdentity.id,
              previewImageUrls: templates[0]?.previewImageUrls,
              thumbnail,
              templates
            };
          } catch (error) {
            return {};
          }
        })
        .filter((template: any) => template.thumbnail);
  
      return {
        templates: visualIdentities,
        count
      };
  } catch (error) {
    console.log(error)
    return {
      count: 0,
      templates: []
    }
  }
}

export default {
  fetchTemplates
}